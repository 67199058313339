import React from 'react';
import styled from 'styled-components';

const AboutContainer = styled.div`
padding: 1rem;
  padding-top: 80px;
  @media (max-width: 950px) {
    padding-top: 20px;
  }
`;

const AboutText = styled.p`
  font-size: 1.2rem;
`;

function About() {
  return (
    <AboutContainer>
<AboutText>
  My journey began in 2014 with running game servers and creating mods, sparking a passion for innovation and problem-solving.
  <br />
  <br />
  In 2021, I founded Nova Software LLC, a retail automation company that generated $1 million in its first year by automating inventory restocking and online deal finding, earning a <b><a href="https://www.forbes.com/sites/laurendebter/2022/11/25/freebie-bots-holiday-shopping/?sh=4c56da564060" target="_blank" rel="noopener noreferrer">feature in Forbes</a></b>.
  <br />
  <br />
  Over the next two years, I developed private scripts in Node.js and Go, creating significant value in the retail automation space.
  <br />
  <br />
  In 2022, I contributed to <a href="https://opensea.io/collection/fishyfam" target="_blank" rel="noopener noreferrer">Fishy Fam</a> by developing custom smart contracts and a React.js site, leading to $30+ million in trading volume on OpenSea. This project marked my entry into crypto development, following my initial investment in Ethereum in 2016.
  <br />
  <br />
  Today, I’m part time on innovative web3 and web2 projects like <a href="https://www.baseboomer.com/" target="_blank" rel="noopener noreferrer">Boomer On Base</a>, simplifying onchain adoption, and <a href="https://carsniper.io/contact" target="_blank" rel="noopener noreferrer">Car Sniper</a>, crushing the third-party car market with a free enterprise solution.
</AboutText>

    </AboutContainer>
  );
}

export default About;
