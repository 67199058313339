import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import Sidebar from './components/Sidebar';
import About from './components/About';
import Projects from './components/Projects';
import Contact from './components/Contact';
import Experience from './components/Experience';
import GradientBackground from './components/GradientBackground';
import AnimatedBackground from './components/AnimatedBackground';
import Preloader from './components/Preloader'; // Import the new Preloader

const AppContainer = styled.div`
  display: flex;
  height: 100vh;
  overflow: hidden;
  
  @media (max-width: 950px) {
    flex-direction: column;
    height: auto; 
    overflow: visible; 
  }
`;

const growIn = keyframes`
  from {
    transform: scale(0.9);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
    `;


const MainContent = styled.main`
  flex-grow: 1;
  margin-left: 420px; 
  overflow-y: auto;
  padding: 3rem;
  background-color: transparent;
  position: relative;
  animation: ${growIn} 0.5s ease-out;

  @media (max-width: 950px) {
    padding: 0.1rem;
    margin-left: 0;
  }

  /* Scrollbar styling */
  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 10px;
  }

  ::-webkit-scrollbar-track {
    background-color: rgba(255, 255, 255, 0.1);
  }

  /* Firefox specific scrollbar styling */
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, 0.5) rgba(255, 255, 255, 0.1);
`;


function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate a network request or some async task
    const timer = setTimeout(() => {
      setLoading(false);
    }, 4200); // Adjust the duration as needed

    return () => clearTimeout(timer); // Cleanup the timer
  }, []);

  return (
    <>
      {loading && <Preloader />}
      {!loading && (
        <AppContainer>
          <GradientBackground />
          <AnimatedBackground />
          <Sidebar />
          <MainContent>
            <section id="about">
              <About />
            </section>
            <section id="projects">
              <Projects />
            </section>
            <section id="experience">
              <Experience />
            </section>
            <section id="contact">
              <Contact />
            </section>
          </MainContent>
        </AppContainer>
      )}
    </>
  );
}

export default App;
