import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import ModalVideo from 'react-modal-video';
import 'react-modal-video/css/modal-video.min.css';
import csImage from '../images/cs.jpg';
import sbImage from '../images/sb.jpg';
import bImage from '../images/b.jpg';
import nbImage from '../images/nb.jpeg';
import nmImage from '../images/nm.png';
import ffImage from '../images/ff.png';
import { CodeTagWithIcon } from '../utils/iconMappings';


const CodeTagsContainer = styled.div`
  display: flex;
  margin-top: 1rem;
  flex-wrap: wrap;

  @media (max-width: 950px) {
    align-items: center;
    text-align: center;
    justify-content: center;
  }
`;

const ShineOverlay = styled.div`
  position: relative;
  overflow: hidden;
  border-radius: 20px
`;

const Shine = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.8) 50%, rgba(255, 255, 255, 0) 100%);
  pointer-events: none;
  z-index: 3;
  opacity: 0;
  transform: skew(30deg);
  animation: shine 0.75s linear forwards;
  display: none;

  @keyframes shine {
    0% {
      left: -50%;
      opacity: 0;
    }
    50% {
      left: 25%;
      opacity: 0.3;
    }
    100% {
      left: 100%;
      opacity: 0;
    }
  }
`;
const ProjectsContainer = styled.div`
  margin-bottom: 4rem;
`;
const ProjectCard = styled.div`
  height: 100%;
  margin-bottom: 1rem;
  display: flex;
  padding: 1rem;
  border: 1px solid transparent;
  border-radius: 8px;
  transition: all 0.3s ease-in-out;
  box-shadow: none;
  cursor: pointer;

  &:hover {
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    background-color: rgba(0, 0, 0, 0.1);
    transform: translateY(-5px);

    ${ShineOverlay} ${Shine} {
      display: block;
    }

    ${ShineOverlay} {
      &:nth-child(1) ${Shine} {
        animation-delay: 0.3s;
      }
      &:nth-child(2) ${Shine} {
        animation-delay: 0.6s;
      }
      &:nth-child(3) ${Shine} {
        animation-delay: 0.9s;
      }
      &:nth-child(4) ${Shine} {
        animation-delay: 1.2s;
      }
      &:nth-child(5) ${Shine} {
        animation-delay: 1.5s;
      }
      &:nth-child(6) ${Shine} {
        animation-delay: 1.8s;
      }
      &:nth-child(7) ${Shine} {
        animation-delay: 2.1s;
      }
      &:nth-child(8) ${Shine} {
        animation-delay: 2.4s;
      }
      &:nth-child(9) ${Shine} {
        animation-delay: 2.7s;
      }
    }
  }

  @media (max-width: 950px) {
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-left: 0;
  }
`;

const ProjectTitle = styled.h3`
  font-size: 1.5rem;
  margin-top: 0;
  margin-bottom: 0;
  
`;

const ProjectImage = styled.img`
  width: 200px;
  min-width: 200px;
  height: auto;
  object-fit: scale-down;
  margin-bottom: 1rem;
  object-position: center;
  border-radius: 8px;
  max-height: 200px;
  margin-left: 8px;
`;

const ProjectContent = styled.div`
  margin-left: 1rem;
  display: flex;
  flex-direction: column;
`;

const ProjectText = styled.p`
  font-size: 1rem;
  margin-top: 0;
`;


const ProjectsHeading = styled.h2`

  @media (max-width: 950px) {
    text-align: center;
  }
`;
function Projects() {
  const [isOpen, setIsOpen] = useState(false);
  const [videoUrl, setVideoUrl] = useState('');
  const [videoRatio, setVideoRatio] = useState('1:1');
  const videoRef = useRef(null);

  const handleCardClickCS = () => {
    window.open('https://carsniper.io', '_blank');
  };

  const handleCardClickSB = () => {
    window.open('https://myserverbuddy.com', '_blank');
  };

  const handleCardClickB = () => {
    window.open('https://baseboomer.com/', '_blank');
  };

  const handleCardClickNB = () => {
    setVideoUrl('/videos/nb.mp4');
    setVideoRatio('16:9');
    setIsOpen(true);
  };

  const handleCardClickNM = () => {
    setVideoUrl('/videos/nm.mp4');
    setVideoRatio('1:1');
    setIsOpen(true);
  };
   
  const handleCardClickFF = () => {
    window.open('https://www.figma.com/design/efFyGJhdBBjgCuDYqcWkIM/Untitled', '_blank');
  };

  const languagesCS = ['Node.js', 'jQuery', 'Tesseract', 'Express', 'MySQL', 'Redis', 'Socket.IO', 'TLS Client'];
  const languagesSB = ['Electron', 'Node.js', 'Powershell', 'jQuery',  'Socket.IO', 'Express', 'MySQL'];
  const languagesB = ['Node.js', 'Ethers', 'Solidity', 'jQuery','Express', 'MySQL', 'Wordpress'];
  const languagesNB = ['Node.js', 'GO', 'Python', 'Electron',  'Squid Proxy', 'jQuery', 'TLS Client', 'Socket.IO', 'Websockets', 'MongoDB'];
  const languagesNM = ['Node.js', 'React Native', 'Expo', 'Socket.IO', 'Express', 'MySQL', 'Redis'];
  const languagesFF = ['Node.js', 'React','Solidity','Wagmi', 'Express','MongoDB'];

  return (
    <ProjectsContainer>
      <ProjectsHeading>Projects</ProjectsHeading>
      
      <ProjectCard onClick={handleCardClickSB}>
        <ProjectImage src={sbImage} alt="Server Buddy" />
        <ProjectContent>
          <ProjectTitle>Server Buddy</ProjectTitle>
          <ProjectText>
            A free Windows Server security and management tool that allows for remote monitoring of servers via a web portal. It offers numerous in-app features for managing your server, including firewalls, IP banning, and much more.
          </ProjectText>
          <CodeTagsContainer>
            {languagesSB.map((language, index) => (
              <ShineOverlay key={index}>
                <Shine className="shine" />
                <CodeTagWithIcon language={language} />
              </ShineOverlay>
            ))}
          </CodeTagsContainer>
        </ProjectContent>
      </ProjectCard>
      <ProjectCard onClick={handleCardClickNB}>
        <ProjectImage src={nbImage} alt="Nova Bot" />
        <ProjectContent>
          <ProjectTitle>Nova Bot</ProjectTitle>
          <ProjectText>
            A retail automation software for Amazon and Walmart that enabled users to automatically purchase high-demand items. The software managed over $50M in inventory with thousands of active users. It also identified free items, which led to being <b><a href="https://www.forbes.com/sites/laurendebter/2022/11/25/freebie-bots-holiday-shopping/?sh=4c56da564060" target="_blank" rel="noopener noreferrer">featured in Forbes!</a></b>
            <br />
            Also built a hyper-scalable cloud version of the software in GO that could handle tens of thousands of users at once.
          </ProjectText>
          <CodeTagsContainer>
            {languagesNB.map((language, index) => (
              <ShineOverlay key={index}>
                <Shine className="shine" />
                <CodeTagWithIcon language={language} />
              </ShineOverlay>
            ))}
          </CodeTagsContainer>
        </ProjectContent>
      </ProjectCard>
      <ProjectCard onClick={handleCardClickB}>
        <ProjectImage src={bImage} alt="Boomer" />
        <ProjectContent>
          <ProjectTitle>Boomer on Base</ProjectTitle>
          <ProjectText>
            A Web3 onboarding project where I led the largest airdrop campaign in Base history, sending alerts to over 100,000 users. This effort helped the project exceed $100 million in trading volume. It then became the first asset in its class to be accepted by a merchant, and was <b><a href="https://finance.yahoo.com/news/boomer-makes-history-first-ever-233500834.html?guccounter=1&guce_referrer=aHR0cHM6Ly93d3cuZ29vZ2xlLmNvbS8&guce_referrer_sig=AQAAAEGjNGUWlMz4AJraYeGr49aVQylLf2pcIAUS1It2dWLaFs8iQhpFARzxb1ELDzsc4iqrbM8v_rY6mvdS0S0DSbvdkTIb0Gl0uGOoNiXUUzlie86JI8IoV92AQITQPpT_V_38KVCNEo9UxxpxhMaCTiW7OihElpku2JXQzDXcOfm3" target="_blank" rel="noopener noreferrer">featured in Yahoo Finance!</a></b>.
          </ProjectText>
          <CodeTagsContainer>
            {languagesB.map((language, index) => (
              <ShineOverlay key={index}>
                <Shine className="shine" />
                <CodeTagWithIcon language={language} />
              </ShineOverlay>
            ))}
          </CodeTagsContainer>
        </ProjectContent>
      </ProjectCard>

      <ProjectCard onClick={handleCardClickCS}>
        <ProjectImage src={csImage} alt="Car Sniper" />
        <ProjectContent>
          <ProjectTitle>Car Sniper</ProjectTitle>
          <ProjectText>
            A free competitor to enterprise dealership software that aggregates listings from all major private-party listing sites. Users can filter, save, and watch as new cars are posted live.
          </ProjectText>
          <CodeTagsContainer>
            {languagesCS.map((language, index) => (
              <ShineOverlay key={index}>
                <Shine className="shine" />
                <CodeTagWithIcon language={language} />
              </ShineOverlay>
            ))}
          </CodeTagsContainer>
        </ProjectContent>
      </ProjectCard>
  

  
      <ProjectCard onClick={handleCardClickNM}>
        <ProjectImage src={nmImage} alt="Nova Mobile" />
        <ProjectContent>
          <ProjectTitle>Nova Mobile</ProjectTitle>
          <ProjectText>
            The mobile companion app for Nova Bot, allowing users to control their bots on the go, receive notifications, and manage their accounts.
          </ProjectText>
          <CodeTagsContainer>
            {languagesNM.map((language, index) => (
              <ShineOverlay key={index}>
                <Shine className="shine" />
                <CodeTagWithIcon language={language} />
              </ShineOverlay>
            ))}
          </CodeTagsContainer>
        </ProjectContent>
      </ProjectCard>
      <ProjectCard onClick={handleCardClickFF}>
        <ProjectImage src={ffImage} alt="Fishy Fam" />
        <ProjectContent>
          <ProjectTitle>Fishy Fam</ProjectTitle>
          <ProjectText>
          Developed Full-stack web app for a project that generated $30M+ in trading volume on OpenSea. Created smart contracts along with several web3 integrations including holder rewards, asset tracking, competitions, and much more.
          </ProjectText>
          <CodeTagsContainer>
            {languagesFF.map((language, index) => (
              <ShineOverlay key={index}>
                <Shine className="shine" />
                <CodeTagWithIcon language={language} />
              </ShineOverlay>
            ))}
          </CodeTagsContainer>
        </ProjectContent>
      </ProjectCard>
  
      {/* ModalVideo component to show the video in a lightbox */}
      <div ref={videoRef}>
        <ModalVideo
          channel="custom"
          isOpen={isOpen}
          url={videoUrl}
          onClose={() => setIsOpen(false)}
          ratio={videoRatio}
          autoplay
        />
      </div>
    </ProjectsContainer>
  );
}

export default Projects;
