// src/utils/iconMappings.js
import React from 'react';
import { FaNodeJs, FaReact, FaServer, FaExpeditedssl, FaImage, FaWordpress, FaHtml5,FaCss3Alt, FaPython } from 'react-icons/fa';
import { TbBrandReactNative } from "react-icons/tb";
import { FaGolang } from "react-icons/fa6";
import { DiRedis, DiPhp } from "react-icons/di";
import { SiMysql, SiElectron, SiPowershell, SiWebrtc, SiSolidity, SiEthers,SiMongodb, SiSocketdotio,SiExpo, SiJquery,SiWagmi  } from 'react-icons/si';
import { IoLogoJavascript } from "react-icons/io5";

import styled from 'styled-components';
import { GiGiantSquid } from "react-icons/gi";

const CustomPythonIcon = () => {
  return (
    <div style={{ position: 'relative', width: '1em', height: '1em' }}>
      {/* Bottom layer - yellow */}
      <FaPython style={{ color: '#FFD43B', position: 'absolute', top: 0, left: 0 }} />
      {/* Top layer - blue */}
      <FaPython style={{ color: '#3776AB', position: 'absolute', top: 0, left: 0 }} />
    </div>
  );
};

// Mapping of language names to icons with brand colors
export const languageIcons = {
  'Node.js': <FaNodeJs style={{ color: '#68A063' }} />,
  'React Native': <TbBrandReactNative style={{ color: '#61DAFB' }} />,
  'React': <FaReact style={{ color: '#61DAFB' }} />,
  'MySQL': <SiMysql style={{ color: '#4479A1' }} />,
  'Redis': <DiRedis style={{ color: '#D82C20' }} />,
  'Electron': <SiElectron style={{ color: '#47848F' }} />,
  'Powershell': <SiPowershell style={{ color: '#5391FE' }} />,
  'Websockets': <SiWebrtc style={{ color: '#4DB33D' }} />,
  'Socket.IO': <SiSocketdotio style={{ color: '#FFFFFF' }} />,
  'TLS Client': <FaExpeditedssl style={{ color: '#2B5797' }} />,
  'Tesseract': <FaImage style={{ color: '#FF8C00' }} />, // Replace with an appropriate color
  'Express': <FaServer style={{ color: 'grey' }} />, // Replace with an appropriate color
  'GO': <FaGolang style={{ color: '#00ADD8' }} />,
  'Solidity': <SiSolidity style={{ color: '#636363' }} />,
  'Ethers': <SiEthers style={{ color: '#929AD1' }} />,
  'Squid Proxy': <GiGiantSquid style={{ color: '#23B1A8' }} />,
  'Wordpress': <FaWordpress style={{ color: '#21759B' }} />,
  'MongoDB': <SiMongodb style={{ color: '#47A248' }} />,
  'Javascript': <IoLogoJavascript style={{ color: '#EFD81D' }} />,
  'HTML': <FaHtml5 style={{ color: '#DD4B25' }} />,
  'CSS': <FaCss3Alt style={{ color: '#254BDD' }} />,
  'PHP': <DiPhp style={{ color: '#F7F7F7' }} />,
  'Expo': <SiExpo style={{ color: '#ECEDEE' }} />,
  'jQuery': <SiJquery style={{ color: '#0865A7' }} />,
  'Wagmi': <SiWagmi style={{ color: 'white' }} />,
  'Python': <CustomPythonIcon />,
};

// Styled component for CodeTag
export const CodeTag = styled.span`
  background-color: #8fafc917;
  color: #c7e2f8;
  border-radius: 20px;
  padding: 0.25rem 0.5rem;
  margin-right: 0.5rem;
  font-size: 1.1rem;
  font-weight: 300;
  margin: 2px;
  display: flex;
  align-items: center;

  svg {
    margin-right: 5px;
  }
`;

// Reusable component for rendering a CodeTag with an icon
export const CodeTagWithIcon = ({ language }) => (
  <CodeTag>
    {languageIcons[language]} {language}
  </CodeTag>
);
