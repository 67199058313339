import React, { useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { ReactTyped } from "react-typed";
const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const orbit = keyframes`
  0% {
    transform: translate(calc(var(--uib-size) * 0.5)) scale(0.73684);
    opacity: 0.65;
  }
  5% {
    transform: translate(calc(var(--uib-size) * 0.4)) scale(0.684208);
    opacity: 0.58;
  }
  10% {
    transform: translate(calc(var(--uib-size) * 0.3)) scale(0.631576);
    opacity: 0.51;
  }
  15% {
    transform: translate(calc(var(--uib-size) * 0.2)) scale(0.578944);
    opacity: 0.44;
  }
  20% {
    transform: translate(calc(var(--uib-size) * 0.1)) scale(0.526312);
    opacity: 0.37;
  }
  25% {
    transform: translate(0%) scale(0.47368);
    opacity: 0.3;
  }
  30% {
    transform: translate(calc(var(--uib-size) * -0.1)) scale(0.526312);
    opacity: 0.37;
  }
  35% {
    transform: translate(calc(var(--uib-size) * -0.2)) scale(0.578944);
    opacity: 0.44;
  }
  40% {
    transform: translate(calc(var(--uib-size) * -0.3)) scale(0.631576);
    opacity: 0.51;
  }
  45% {
    transform: translate(calc(var(--uib-size) * -0.4)) scale(0.684208);
    opacity: 0.58;
  }
  50% {
    transform: translate(calc(var(--uib-size) * -0.5)) scale(0.73684);
    opacity: 0.65;
  }
  55% {
    transform: translate(calc(var(--uib-size) * -0.4)) scale(0.789472);
    opacity: 0.72;
  }
  60% {
    transform: translate(calc(var(--uib-size) * -0.3)) scale(0.842104);
    opacity: 0.79;
  }
  65% {
    transform: translate(calc(var(--uib-size) * -0.2)) scale(0.894736);
    opacity: 0.86;
  }
  70% {
    transform: translate(calc(var(--uib-size) * -0.1)) scale(0.947368);
    opacity: 0.93;
  }
  75% {
    transform: translate(0%) scale(1);
    opacity: 1;
  }
  80% {
    transform: translate(calc(var(--uib-size) * 0.1)) scale(0.947368);
    opacity: 0.93;
  }
  85% {
    transform: translate(calc(var(--uib-size) * 0.2)) scale(0.894736);
    opacity: 0.86;
  }
  90% {
    transform: translate(calc(var(--uib-size) * 0.3)) scale(0.842104);
    opacity: 0.79;
  }
  95% {
    transform: translate(calc(var(--uib-size) * 0.4)) scale(0.789472);
    opacity: 0.72;
  }
  100% {
    transform: translate(calc(var(--uib-size) * 0.5)) scale(0.73684);
    opacity: 0.65;
  }
`;
const WholePage = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    overflow: hidden;
    background-color: rgb(26, 25, 29); /* or another color to distinguish sections */
    flex-direction: column;
`;

const Container = styled.div`
  --uib-size: 105px;
  --uib-color: white;
  --uib-speed: 1.75s;
  position: relative;
  height: var(--uib-size);
  width: var(--uib-size);
  animation: ${rotate} calc(var(--uib-speed) * 4) linear infinite;
`;

const flyOut = keyframes`
  0% {
    transform: translate(0, 0) scale(1);
    opacity: 1;
  }
  100% {
    transform: translate(calc(var(--random-x) * 100vw), calc(var(--random-y) * 100vh)) scale(0.5);
    opacity: 0;
  }
`;

const Particle = styled.div`
  position: absolute;
  top: 0%;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  color: white;

  &:nth-child(1) {
    --random-x: ${() => Math.random() * 2 - 1};
    --random-y: ${() => Math.random() * 2 - 1};
    --uib-delay: 0;
    transform: rotate(8deg);
    animation: ${flyOut} 1.5s ease-in-out forwards;
    animation-delay: 3.3s;
  }
  &:nth-child(2) {
    --random-x: ${() => Math.random() * 2 - 1};
    --random-y: ${() => Math.random() * 2 - 1};
    --uib-delay: -0.4;
    transform: rotate(36deg);
    animation: ${flyOut} 1.5s ease-in-out forwards;
    animation-delay: 3.3s;
  }
  &:nth-child(3) {
    --random-x: ${() => Math.random() * 2 - 1};
    --random-y: ${() => Math.random() * 2 - 1};
    --uib-delay: -0.9;
    transform: rotate(72deg);
    animation: ${flyOut} 1.5s ease-in-out forwards;
    animation-delay: 3.3s;
  }
  &:nth-child(4) {
    --random-x: ${() => Math.random() * 2 - 1};
    --random-y: ${() => Math.random() * 2 - 1};
    --uib-delay: -0.5;
    transform: rotate(90deg);
    animation: ${flyOut} 1.5s ease-in-out forwards;
    animation-delay: 3.3s;
  }
  &:nth-child(5) {
    --random-x: ${() => Math.random() * 2 - 1};
    --random-y: ${() => Math.random() * 2 - 1};
    --uib-delay: -0.3;
    transform: rotate(144deg);
    animation: ${flyOut} 1.5s ease-in-out forwards;
    animation-delay: 3.3s;
  }
  &:nth-child(6) {
    --random-x: ${() => Math.random() * 2 - 1};
    --random-y: ${() => Math.random() * 2 - 1};
    --uib-delay: -0.2;
    transform: rotate(180deg);
    animation: ${flyOut} 1.5s ease-in-out forwards;
    animation-delay: 3.3s;
  }
  &:nth-child(7) {
    --random-x: ${() => Math.random() * 2 - 1};
    --random-y: ${() => Math.random() * 2 - 1};
    --uib-delay: -0.6;
    transform: rotate(216deg);
    animation: ${flyOut} 1.5s ease-in-out forwards;
    animation-delay: 3.3s;
  }
  &:nth-child(8) {
    --random-x: ${() => Math.random() * 2 - 1};
    --random-y: ${() => Math.random() * 2 - 1};
    --uib-delay: -0.7;
    transform: rotate(252deg);
    animation: ${flyOut} 1.5s ease-in-out forwards;
    animation-delay: 3.3s;
  }
  &:nth-child(9) {
    --random-x: ${() => Math.random() * 2 - 1};
    --random-y: ${() => Math.random() * 2 - 1};
    --uib-delay: -0.1;
    transform: rotate(300deg);
    animation: ${flyOut} 1.5s ease-in-out forwards;
    animation-delay: 3.3s;
  }
  &:nth-child(10) {
    --random-x: ${() => Math.random() * 2 - 1};
    --random-y: ${() => Math.random() * 2 - 1};
    --uib-delay: -0.8;
    transform: rotate(324deg);
    animation: ${flyOut} 1.5s ease-in-out forwards;
    animation-delay: 3.3s;
  }
  &:nth-child(11) {
    --random-x: ${() => Math.random() * 2 - 1};
    --random-y: ${() => Math.random() * 2 - 1};
    --uib-delay: -1.2;
    transform: rotate(335deg);
    animation: ${flyOut} 1.5s ease-in-out forwards;
    animation-delay: 3.3s;
  }
  &:nth-child(12) {
    --random-x: ${() => Math.random() * 2 - 1};
    --random-y: ${() => Math.random() * 2 - 1};
    --uib-delay: -0.5;
    transform: rotate(290deg);
    animation: ${flyOut} 1.5s ease-in-out forwards;
    animation-delay: 3.3s;
  }
  &:nth-child(13) {
    --random-x: ${() => Math.random() * 2 - 1};
    --random-y: ${() => Math.random() * 2 - 1};
    --uib-delay: -0.2;
    transform: rotate(240deg);
    animation: ${flyOut} 1.5s ease-in-out forwards;
    animation-delay: 3.3s;
  }

  &::before {
    content: '';
    position: absolute;
    height: 17.5%;
    width: 17.5%;
    border-radius: 50%;
    background-color: var(--uib-color);
    flex-shrink: 0;
    transition: background-color 0.3s ease;
    --uib-d: calc(var(--uib-delay) * var(--uib-speed));
    animation: ${orbit} var(--uib-speed) linear var(--uib-d) infinite;
  }
`;

const Preloader = () => {
  const statuses = ["I build apps.", "I build websites.", "I build fast."];
    // after 5 seconds hide the ReactTyped
    useEffect(() => {
        setTimeout(() => {
            document.querySelector('.Typed').style.display = 'none';
        }, 3300);
    }, []);
  return (
    <WholePage>
      <Container>
        <Particle />
        <Particle />
        <Particle />
        <Particle />
        <Particle />
        <Particle />
        <Particle />
        <Particle />
        <Particle />
        <Particle />
        <Particle />
        <Particle />
        <Particle />
      </Container>
      <ReactTyped
        strings={statuses}
        typeSpeed={16}
        backSpeed={2}
        className='Typed'
        style={{ color: 'white', marginTop: '20px', fontSize: '1.5rem' }}
      />
    </WholePage>
  );
};

export default Preloader;