import React, { useEffect } from 'react';

const AnimatedBackground = () => {
  useEffect(() => {
    var width, height, largeHeader, canvas, ctx, circles, target = { x: 0, y: 0 }, animateHeader = true;

    initHeader();
    addListeners();

    function initHeader() {
      width = window.innerWidth;
      height = window.innerHeight;
      target = { x: width / 2, y: height / 2 };

      largeHeader = document.getElementById('large-header');
      largeHeader.style.height = height + 'px';

      canvas = document.getElementById('demo-canvas');
      canvas.width = width;
      canvas.height = height;
      ctx = canvas.getContext('2d');

      circles = [];
      for (var x = 0; x < width * 0.1; x++) {
        var c = new Circle();
        circles.push(c);
      }
      animate();
    }

    function addListeners() {
      window.addEventListener('scroll', scrollCheck);
      window.addEventListener('resize', resize);
      window.addEventListener('mousemove', mouseMove);
    }

    function mouseMove(e) {
      target.x = e.clientX;
      target.y = e.clientY;
    }

    function scrollCheck() {
      if (document.body.scrollTop > height) animateHeader = false;
      else animateHeader = true;
    }

    function resize() {
      width = window.innerWidth;
      height = window.innerHeight;
      largeHeader.style.height = height + 'px';
      canvas.width = width;
      canvas.height = height;
    }

    function animate() {
      if (animateHeader) {
        ctx.clearRect(0, 0, width, height);
        for (var i in circles) {
          circles[i].draw();
        }
      }
      requestAnimationFrame(animate);
    }

    function Circle() {
      var _this = this;

      (function () {
        _this.pos = {};
        init();
      })();

      function init() {
        _this.pos.x = Math.random() * width;
        _this.pos.y = Math.random() * height;
        _this.alpha = 0.035 + Math.random() * 0.035; // Keeping transparency intact
        _this.scale = 0.1 + Math.random() * 0.3;
        _this.velocity = 0.3 + Math.random() * 0.3; // Adjusting the velocity
        _this.moveFactor = 0.0012 + Math.random() * 0.0012; // Slower and subtler movement
      }

      this.draw = function () {
        if (_this.alpha <= 0) {
          init();
        }

        // Gradually move the circles towards the target with a subtle effect
        _this.pos.x += (_this.pos.x - target.x) * -_this.moveFactor;
        _this.pos.y += (_this.pos.y - target.y) * -_this.moveFactor;

        _this.pos.y -= _this.velocity; // Circle movement continues to go upwards

        _this.alpha -= 0.0005; // Keeping the fade out effect

        ctx.beginPath();
        ctx.arc(_this.pos.x, _this.pos.y, _this.scale * 10, 0, 2 * Math.PI, false);
        ctx.fillStyle = 'rgba(255,255,255,' + _this.alpha + ')';
        ctx.fill();
      };
    }

    // Clean up event listeners on component unmount
    return () => {
      window.removeEventListener('scroll', scrollCheck);
      window.removeEventListener('resize', resize);
      window.removeEventListener('mousemove', mouseMove);
    };
  }, []);

  return (
    <div id="large-header" className="large-header">
      <canvas id="demo-canvas"></canvas>
    </div>
  );
};

export default AnimatedBackground;
