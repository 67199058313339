import React from 'react';
import styled from 'styled-components';

const ContactContainer = styled.div`
  padding: 2rem;
  text-align: center;
`;

const ContactButton = styled.a`
  display: inline-block;
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  background-color: #DBDADB;
  color: #1A191D;
  font-weight: 700;
  text-decoration: none;
  border-radius: 5px;
  border: 2px solid transparent; /* Add a transparent border initially */
  box-sizing: border-box; /* Ensures padding and border are included in the width */

  &:hover {
    background-color: #00000000;
    border: 2px solid #DBDADB;
    color: #DBDADB;
  }
`;

function Contact() {
  return (
    <ContactContainer>
      <h2>Contact Me</h2>
      <p>will@griftech.dev</p>
      <ContactButton href="mailto:will@griftech.dev">Send an Email</ContactButton>
    </ContactContainer>
  );
}

export default Contact;
