import React, { useEffect } from 'react';
import styled from 'styled-components';

const GradientContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none; /* Ensures the gradient doesn't interfere with clicking elements */
  z-index: -1; /* Places the gradient behind other content */
`;

const GradientBackground = () => {
  useEffect(() => {
    const handleMouseMove = (event) => {
      const { clientX, clientY } = event;
      const windowWidth = window.innerWidth;
      const windowHeight = window.innerHeight;

      // Calculate the position as a percentage of the window dimensions
      const xPercent = (clientX / windowWidth) * 100;
      const yPercent = (clientY / windowHeight) * 100;

      const gradientElement = document.getElementById('gradient-background');
      if (gradientElement) {
        gradientElement.style.background = `
          radial-gradient(circle at ${xPercent}% ${yPercent}%, 
                          rgba(53, 45, 59, 0.9) 0%, 
                          rgba(26, 25, 29, 0.7) 30%,
                          transparent 100%)
        `;
      }
    };

    window.addEventListener('mousemove', handleMouseMove);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  return <GradientContainer id="gradient-background" />;
};

export default GradientBackground;
