import React from 'react';
import styled from 'styled-components';
import { CodeTagWithIcon } from '../utils/iconMappings'; // Adjust the path as necessary

const ExperiencesContainer = styled.div`
  margin-bottom: 4rem;
`;
const ShineOverlay = styled.div`
  position: relative;
  overflow: hidden;
  border-radius: 20px;
`;

const Shine = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.8) 50%, rgba(255, 255, 255, 0) 100%);
  pointer-events: none;
  z-index: 3;
  opacity: 0;
  transform: skew(30deg);
  animation: shine 0.75s linear forwards;
  display: none;

  @keyframes shine {
    0% {
      left: -50%;
      opacity: 0;
    }
    50% {
      left: 25%;
      opacity: 0.3;
    }
    100% {
      left: 100%;
      opacity: 0;
    }
  }
`;

const ExperienceCard = styled.div`
  height: 100%;
  margin-bottom: 1rem;
  margin-left: -16px;
  display: flex;
  padding: 1rem;
  border: 1px solid transparent;
  border-radius: 8px;
  transition: all 0.3s ease-in-out;
  box-shadow: none;
  cursor: default;

  &:hover {
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    background-color: rgba(0, 0, 0, 0.1);
    transform: translateY(-5px);

    ${ShineOverlay} ${Shine} {
      display: block;
    }

    ${ShineOverlay} {
      &:nth-child(1) ${Shine} {
        animation-delay: 0.3s;
      }
      &:nth-child(2) ${Shine} {
        animation-delay: 0.6s;
      }
      &:nth-child(3) ${Shine} {
        animation-delay: 0.9s;
      }
      &:nth-child(4) ${Shine} {
        animation-delay: 1.2s;
      }
      &:nth-child(5) ${Shine} {
        animation-delay: 1.5s;
      }
      &:nth-child(6) ${Shine} {
        animation-delay: 1.8s;
      }
      &:nth-child(7) ${Shine} {
        animation-delay: 2.1s;
      }
      &:nth-child(8) ${Shine} {
        animation-delay: 2.4s;
      }
      &:nth-child(9) ${Shine} {
        animation-delay: 2.7s;
      }
      &:nth-child(10) ${Shine} {
        animation-delay: 3s;
      }
      &:nth-child(11) ${Shine} {
        animation-delay: 3.3s;

      }
    }
  }

  @media (max-width: 950px) {
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-left: 0;
  }
`;

const ExperienceTitle = styled.h3`
  font-size: 1.5rem;
  margin-top: 0;
  margin-bottom: 0;
`;

const ExperienceContent = styled.div`
  margin-left: 1rem;
  display: flex;
  flex-direction: column;
`;

const ExperienceText = styled.p`
  font-size: 1rem;
  margin-top: 0;
`;

const CodeTagsContainer = styled.div`
  display: flex;
  margin-top: 1rem;
  flex-wrap: wrap;
  @media (max-width: 950px) {
    align-items: center;
    text-align: center;
    justify-content: center;
  }
`;

const ExperienceTime = styled.p`
  font-size: .8rem;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 0;
  min-width: 110px;
  max-width: 110px;
  opacity: 0.7;
`;
const ExperienceHeading = styled.h2`

  @media (max-width: 950px) {
    text-align: center;
  }
`;

function Experiences() {
  const languagesGT = ['Node.js', 'GO', 'Python', 'Solidity', 'React', 'Electron', 'jQuery', 'Socket.IO', 'MySQL', 'Redis', 'TLS Client'];
  const languagesNS = ['Node.js', 'GO', 'Python', 'React Native', 'Electron', 'jQuery', 'Socket.IO', 'Websockets', 'MongoDB', 'MySQL', 'Redis', 'TLS Client'];
  const languagesBMP = ['Javascript', 'HTML', 'CSS', 'PHP', 'Wordpress'];
  const languagesOB = ['Node.js', 'GO'];
  const languagesUT = ['Python', 'MySQL'];
  return (
    <ExperiencesContainer>
      <ExperienceHeading>Experiences</ExperienceHeading>
      
      <ExperienceCard>
        <ExperienceTime>2021 — Present</ExperienceTime>
        <ExperienceContent>
          <ExperienceTitle>Full-Stack Developer · Grif Tech</ExperienceTitle>
          <ExperienceText>
            Founded a software company responsible for developing the projects mentioned above, along with numerous private projects. 
            <br />
            I specialize in building custom web apps, web scraping, and APIs, delivering simple solutions for complex problems. I contribute from the design stage through to production deployment. My intuitive problem-solving approach has led to a 100% client satisfaction rate across more than a dozen projects.
          </ExperienceText>
          <CodeTagsContainer>
            {languagesGT.map((language, index) => (
              <ShineOverlay key={index}>
                <Shine className="shine" />
                <CodeTagWithIcon language={language} />
              </ShineOverlay>
            ))}
          </CodeTagsContainer>
        </ExperienceContent>
      </ExperienceCard>
      
      <ExperienceCard>
        <ExperienceTime>2021 — 2023</ExperienceTime>
        <ExperienceContent>
          <ExperienceTitle>Lead Developer · Nova Software</ExperienceTitle>
          <ExperienceText>
            Led the development of the leading retail automation software for Amazon, achieving over $50 million in product acquisitions within two years. 
            <br />
            Developed an Electron desktop app compatible with Windows, Mac, and Linux, and created a React Native app enabling mobile users to control their software on the go. I focused heavily on back-end optimizations for our task engine including a re-write from Node.js to GO. With my help building the application from the ground up, we grossed $1 million in its first year of operation.
          </ExperienceText>
          <CodeTagsContainer>
            {languagesNS.map((language, index) => (
              <ShineOverlay key={index}>
                <Shine className="shine" />
                <CodeTagWithIcon language={language} />
              </ShineOverlay>
            ))}
          </CodeTagsContainer>
        </ExperienceContent>
      </ExperienceCard>
  
      <ExperienceCard>
        <ExperienceTime>2019 — 2021</ExperienceTime>
        <ExperienceContent>
          <ExperienceTitle>Project Coordinator · BMP</ExperienceTitle>
          <ExperienceText>
          Oversaw client website builds and collaborated with multiple developers to streamline development processes. Managed and maintained open communication with clients and developers to ensure on-time delivery. Developed, quality-controlled, and implemented CI/CD pipeline rules for website projects, resulting in faster deployments and improved code quality.
          </ExperienceText>
          <CodeTagsContainer>
            {languagesBMP.map((language, index) => (
              <ShineOverlay key={index}>
                <Shine className="shine" />
                <CodeTagWithIcon language={language} />
              </ShineOverlay>
            ))}
          </CodeTagsContainer>
        </ExperienceContent>
      </ExperienceCard>
  
      <ExperienceCard>
        <ExperienceTime>2018 — 2019</ExperienceTime>
        <ExperienceContent>
          <ExperienceTitle>Junior Software Engineer · Ominous Blockchain</ExperienceTitle>
          <ExperienceText>
            Managed the company's mining pools and monitored servers. Conducted marketing campaigns to attract more miners to the network.
            <br />
            I worked on building a distributed storage network in Go, inspired by <a href="https://Sia.tech" target="_blank" rel="noopener noreferrer">Sia.tech</a>. Learned systems design and architecture to build a new form of distributed storage.
          </ExperienceText>
          <CodeTagsContainer>
            {languagesOB.map((language, index) => (
              <ShineOverlay key={index}>
                <Shine className="shine" />
                <CodeTagWithIcon language={language} />
              </ShineOverlay>
            ))}
          </CodeTagsContainer>
        </ExperienceContent>
      </ExperienceCard>
      <ExperienceHeading>Education</ExperienceHeading>
      <ExperienceCard>
        <ExperienceTime>2016 — 2019</ExperienceTime>
        <ExperienceContent>
          <ExperienceTitle>MIS · University of Tampa</ExperienceTitle>
          <ExperienceText>
            Graduated with a Bachelor of Science in Management Information Systems. 
            <br />
            Learned system design and development, database management, and business intelligence. Took additional computer science courses for Python and MySQL.
          </ExperienceText>
          <CodeTagsContainer>
            {languagesUT.map((language, index) => (
              <ShineOverlay key={index}>
                <Shine className="shine" />
                <CodeTagWithIcon language={language} />
              </ShineOverlay>
            ))}
          </CodeTagsContainer>
        </ExperienceContent>
      </ExperienceCard>
    </ExperiencesContainer>
  );  
}

export default Experiences;
