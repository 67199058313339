import React from 'react';
import styled, { keyframes } from 'styled-components';
import { FaLinkedin } from 'react-icons/fa';

import { ReactTyped } from "react-typed";

// Keyframes for animations
const slideIn = keyframes`
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;



const SidebarContainer = styled.div`
  width: 600px;
  color: white;
  padding-top: 80px;
  padding-bottom: 15%;
  padding-left: 60px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: fixed;
  min-height: 200px;
  height: 600px;
  top: 0;
  left: 0;
  animation: ${slideIn} 0.5s ease-out;
  overflow-y: auto;

  @media (max-width: 950px) {
    position: relative;
    width: 100%;
    height: auto;
    padding: 0px;
    overflow-y: visible;
  }
`;

const PersonalInfo = styled.div`
  margin-bottom: 2rem;
  opacity: 0;
  animation: ${fadeIn} 1s ease-out forwards;
  animation-delay: 0.3s;

  h1 {
    font-size: 2.5rem;
    margin-bottom: 0.5rem;
    color: white;
  }

  h3 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    color: #C0C0C0;
  }

  p {
    font-size: 1rem;
    color: #f0f0f0;
  }

  @media (max-width: 950px) {
    text-align: center;
    margin-bottom: 1rem;
  }
`;

const NavLink = styled.a`
  color: white;
  text-decoration: none;
  margin-bottom: 1rem;
  display: block;
  position: relative;
  transition: color 0.3s ease;

  &:hover {
    text-decoration: underline;
    color: #f7d774;
  }

  &:before {
    content: '';
    position: absolute;
    bottom: -5px;
    left: 0;
    width: 100%;
    height: 2px;
    transform: scaleX(0);
    transform-origin: right;
    transition: transform 0.3s ease;
  }

  &:hover:before {
    transform: scaleX(1);
    transform-origin: left;
  }

  opacity: 0;
  animation: ${fadeIn} 1s ease-out forwards;
  animation-delay: 0.6s;

  @media (max-width: 950px) {
    margin-right: 0;
    text-align: center;
    font-size: 1.2rem;
  }
`;
const DownloadButton = styled.a`
  display: inline-block;
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  background-color: #DBDADB;
  color: #1A191D;
  font-weight: 700;
  text-decoration: none;
  border-radius: 5px;
  width: 150px;
  font-size: 0.8rem;
  text-align: center;
  border: 2px solid transparent; /* Add a transparent border initially */
  box-sizing: border-box; /* Ensures padding and border are included in the width */

  &:hover {
    background-color: #00000000;
    border: 2px solid #DBDADB; /* Border remains the same size */
    color: #DBDADB;
  }

  @media (max-width: 950px) {
    margin: 1rem auto;
  }
`;


const SocialLinks = styled.div`
  margin-top: 2rem;
  opacity: 0;
  animation: ${fadeIn} 1s ease-out forwards;
  animation-delay: 0.9s;

  display: flex;
  justify-content: start;
  gap: 1rem;

  @media (max-width: 950px) {
    justify-content: center;
    margin-top: 1rem;
    gap: 2rem;
  }

  a {
    color: rgba(239, 239, 239, 0.55);
    font-size: 2rem;
    transition: color 0.3s ease;

    &:hover {
      color: #FFFFFF;
    }

    &.linkedin:hover {
      color: #0077B5;
    }
  }
`;

const JobTitle = styled.h3``;
const WhatIDo = styled.p``;

function Sidebar() {
  const taglines = ["I build new ideas into reality", "I build from the ground up", "I love building new things", "I never stop building"];
  return (
    <SidebarContainer>
      <PersonalInfo>
        <h1>William Rogers</h1>
        <JobTitle>Engineer & Founder</JobTitle>
        <WhatIDo>
          <ReactTyped
            startWhenVisible={true}
            strings={taglines}
            typeSpeed={40}
            backSpeed={50}
          />
        </WhatIDo>
      </PersonalInfo>
      <nav>
        <NavLink href="#about">About</NavLink>
        <NavLink href="#projects">Projects</NavLink>
        <NavLink href="#experience">Experience</NavLink>
        <NavLink href="#contact">Contact</NavLink>
      </nav>
      <DownloadButton href="/William-Rogers-Resume.pdf" download>
        Download Resume
      </DownloadButton>
      <SocialLinks>
        <a
          href="https://www.linkedin.com/in/william-rogers-973519123/"
          target="_blank"
          rel="noopener noreferrer"
          className="linkedin"
        >
          <FaLinkedin />
        </a>
      </SocialLinks>
    </SidebarContainer>
  );
}

export default Sidebar;
