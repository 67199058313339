import React from 'react';
import ReactDOM from 'react-dom/client'; // Import from 'react-dom/client' instead of 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import './index.css';

// Create the root element using createRoot
const root = ReactDOM.createRoot(document.getElementById('root'));

// Render the application
root.render(

    <Router>
      <App />
    </Router>
);
